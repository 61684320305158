/**
 * Backbone patched patches backbone post 1.0 release which removed auto-binding
 * of passed-in options to backbone views
 */
define(
  'backbonepatched',

  // Define require.js dependencies
  ['backboneoriginal', 'underscore'],

  function backbonepatched(Backbone, _) {
    // Patch options
    // eslint-disable-next-line no-param-reassign
    Backbone.View = (function BackbonePatchedView(View) {
      return View.extend({
        constructor: function constructor(options) {
          this.options = options || {};

          View.apply(this, arguments);
        },

        overrideDefaults: function overrideDefaults(defaults) {
          // assign properties. look to this.options, fall back on defaults
          Object.keys(defaults).forEach(
            function name(key) {
              var def = defaults[key];
              var override = this.options[key];
              var value =
                !_.isArray(override) &&
                _.isObject(override) &&
                !_.isFunction(override) &&
                _.isObject(def) &&
                !_.isFunction(def)
                  ? _.extend({}, def, override)
                  : override;

              this[key] = _.isUndefined(override) ? def : value;
            }.bind(this)
          );
        },
      });
    }(Backbone.View));

    // Patch options
    // eslint-disable-next-line no-param-reassign
    Backbone.Collection = (function BackbonePatchedCollection(Collection) {
      return Collection.extend({
        constructor: function constructor(models, options) {
          this.options = options || {};

          this.url = this.localizeEndpoint(this.endpoint);

          Collection.apply(this, arguments);
        },

        localizeEndpoint: function localizeEndpoint() {
          // attempt to find the language prefix
          var lang = window.URL_LANGUAGE_PREFIX;
          // prefix the endpoint if found (add leading/trailing slashes)
          return '/' + (lang ? lang + '/' + this.endpoint : this.endpoint) + '/';
        },
      });
    }(Backbone.Collection));

    return Backbone;
  }
);
