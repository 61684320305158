/**
 * GridGuide defines the grid module overlay used to make sure page elements
 * are lined up properly within our grid system
 */
define(
  'gridguide',

  // Define require.js dependencies
  ['underscore', 'backbone'],

  function gridguide(_, Backbone) {
    return Backbone.View.extend({
      /**
       * Element after which the grid guide will be inserted.
       * Applications can set this to something different if they
       * use a classname other than "page-wrapper" on their pages.
       */
      el: '.page-wrapper',

      /**
       * Grid Guide Template
       * Set here so there's always a default. Applications can set
       * their own grid template if needed.
       */
      template: _.template(
        [
          '<div class="grid-guide grid-guide-<%= direction %>">',
          '<div class="grid-guide-<%= direction %>-inner">',
          '<% _.times( count, function() { %>',
          '<% if ( direction === "vert" ) { %>',
          '<div class="grid-guide-col"></div>',
          '<% } else { %>',
          '<div class="grid-guide-row" style="height:<%= rowHeight %>px;margin-bottom:<%= rowHeight %>px;"></div>',
          '<% } %>',
          '<% } ); %>',
          '</div>',
          '</div>',
        ].join('')
      ),

      /**
       * Grid Column Count
       * Applications can set this if they use a different number of columns
       * for their grid.
       */
      gridColumnCount: 24,

      initialize: function initialize() {
        // Set up view-level variables
        this.setVariables();

        // set up event listeners
        this.setListeners();
      },

      // Begin setter functions
      setVariables: function setVariables() {
        // Set up grid variable caching
        this.$verticalGuide = null;
        this.$horizontalGuide = null;
      },

      setListeners: function setListeners() {
        $(document).on('keydown', this.handleKeydown.bind(this));
      },
      // End setter functions

      // Begin event listener functions
      handleKeydown: function handleKeydown(e) {
        // Detect control and shift key presses
        if (e.ctrlKey && e.shiftKey) {
          // 71 === g
          if (e.which === 71) {
            this.toggleVerticalGuide();
          }

          // 72 === h
          if (e.which === 72) {
            this.toggleHorizontalGuide();
          }
        }
      },
      // End event listener functions

      // Begin toggle functions
      toggleVerticalGuide: function toggleVerticalGuide() {
        // Check if grid is already created, toggle it if so
        if (this.$verticalGuide) {
          this.$verticalGuide.toggle();

          return;
        }

        // form the vertical grid guide
        this.$verticalGuide = $(
          this.template({
            direction: 'vert',
            count: this.gridColumnCount,
          })
        );

        // insert the vertical grid guide
        this.$el.after(this.$verticalGuide);
      },

      toggleHorizontalGuide: function toggleHorizontalGuide() {
        var $body;
        var rowCount;
        var rowHeight;

        // Check if grid is already created, toggle it if so
        if (this.$horizontalGuide) {
          this.$horizontalGuide.toggle();

          return;
        }

        // get the body element
        $body = $('body');

        // get the rowHeight
        rowHeight =
          $body.css('line-height') === 'normal' ? 16 : parseInt($body.css('line-height'), 10);

        // figure out the row count
        // each row takes up rowHeight * 2 allowing for margin
        rowCount = Math.floor($body.height() / (rowHeight * 2));

        // form the horizontal grid guide
        this.$horizontalGuide = $(
          this.template({
            direction: 'horiz',
            count: rowCount,
            rowHeight: rowHeight,
          })
        );

        // insert the horizontal grid guide
        this.$el.after(this.$horizontalGuide);
      },
      // End toggle functions
    });
  }
);
