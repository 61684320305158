/**
 * Utils defines a set of utility functions that can be included if needed.
 */
define(
  'utils',

  // Define require.js dependencies
  ['jquery', 'underscore', 'momentextended'],

  function utils($, _, moment) {
    var Utils = {
      // domLoad is used to call a callback upon either the DOM already being loaded,
      // or upon load
      domLoad: function domLoad(callback) {
        // Check callback
        callback = this.errorCheckCallback(callback);

        return this.getDocumentReadyState() !== 'loading'
          ? callback()
          : document.addEventListener('DOMContentLoaded', callback);
      },

      /**
       * Ensures that an input DOM element is either already a jQuery
       * object or converts it to one
       *
       * @param  {mixed}  el     Either a dom node or a jquery object
       * @param  {jquery} jquery An optional reference to jquery, useful when the
       *                         library that is calling this function has a
       *                         different version of `$` than `mp-bower-assets`
       *
       * @return {jquery}        The el, wrapped in a `$` object
       */
      ensureJqueryObject: function ensureJqueryObject(el, jquery) {
        jquery = jquery || $;

        return el instanceof jquery ? el : jquery(el);
      },

      /**
       * ensureInstanceOf ensures that an input object is an instance
       * of an input constructor. if so, the original object is return.
       * if not, a new instance of the constructor is returned
       *
       * NOTE: If the contructor needs to be passed arguments, they can
       * be bound to the constructor function when it's passed.
       * Ex:
       * c = Utils.ensureInstanceOf( c, C.bind( C, { option: "foo" } ) )
       */
      ensureInstanceOf: function ensureInstanceOf(object, Constructor) {
        return object instanceof Constructor ? object : new Constructor();
      },

      // errorCheckCallback checks that a callback function is valid,
      // otherwise defines an empty function and returns it
      errorCheckCallback: function errorCheckCallback(callback) {
        // Check for valid callback, add default if needed
        return callback === undefined || typeof callback !== 'function'
          ? function noop() {}
          : callback;
      },

      /**
       * raf aliases window.requestAnimationFrame if available, otherwise it becomes
       * a function that passes a callback function to a setTimeout designed to
       * run after ~16.6ms to mimic a 60fps frame rate.
       *
       * @param  {function} callback Callback function
       */
      raf: function raf(callback) {
        // get raf or polyfill it
        var newRaf =
          this.getRaf() ||
          function newRaf(cb) {
            setTimeout(cb, 1000 / 60);
          };

        // verify callback is a function
        callback = this.errorCheckCallback(callback);

        // call raf
        newRaf(callback);
      },

      // returns window.requestAnimationFrame (stubbable)
      getRaf: function getRaf() {
        return window.requestAnimationFrame;
      },

      /**
       * Attempts to retrieve a key from a source object who's value matches
       * the specified input. If the key couldn't be found, returns false or
       * a specified fallback value.
       *
       * NOTE: This method will return the key of the first instance found for the given
       * value. That means that, for an object containing duplicate values (think booleans),
       * this method will not provide full accuracy
       *
       * @param  {mixed} value        Value to search for
       * @param  {Object} source      Source object to search
       * @param  {mixed} fallback     Value to be returned if key could not be found
       *
       * @return {mixed}              Returns the key if found, otherwise the specified fallback
       */
      getKey: function getKey(value, source, fallback) {
        // Loop variable
        var key = fallback || false;

        // Loop through object properties
        _.each(source, function checkKeys(val, prop) {
          if (val === value) {
            key = prop;
          }
        });

        // Return fallback
        return key;
      },

      /**
       * Attempts to retrieve a value from a source object. If
       * the value couldn't be found, returns false or a specified
       * fallback value.
       *
       * @param  {string} key      Key of value to be retrieved. Can also be
       *                           specified using dot notation to retrieve
       *                           nested values. Ex: "content.title"
       * @param  {Object} source   Source object.
       * @param  {mixed}  fallback Value to be returned if key could not be
       *                           retrieved.
       * @return {mixed}           Value of the key, false, or specified fallback
       */
      getValue: function getValue(key, source, fallback) {
        var parts;

        // use provided default or false
        fallback = typeof fallback === 'undefined' ? false : fallback;

        if (_.isEmpty(key) || _.isEmpty(source)) {
          return fallback;
        }
        // get the key parts
        parts = key.split('.');

        // shift the first key off the front
        key = parts.shift();

        // if the source doesn't contain the key or value is undefined, return the fallback
        if (
          !source ||
          !Object.prototype.hasOwnProperty.call(source, key) ||
          typeof source[key] === 'undefined'
        ) {
          return fallback;
        }

        // if there are left over key parts, recurse. otherwise return the value
        return parts.length ? this.getValue(parts.join('.'), source[key], fallback) : source[key];
      },

      // formUrlWithParams takes a URL and an object of params and forms
      // a valid URL with them
      formUrlWithParams: function formUrlWithParams(url, params) {
        // Form params with fallback
        params = params === undefined || typeof params !== 'object' ? {} : params;

        // Check if there are params to append
        if (!Object.keys(params).length) {
          return url;
        }

        // Check for any existing params
        url += url.indexOf('?') !== -1 ? '&' : '?';

        _.each(params, function buildUrl(value, key) {
          // If the param value is "null", treat as a boolean param
          url += key + (value === null ? '' : '=' + value) + '&';
        });

        // Remove trailing ampersands
        return url.replace(/&$/, '');
      },

      // Checks for entry on CGI params and returns value or false
      getQueryVariable: function getQueryVariable(variable) {
        var query = this.getWindowLocationSearch().substring(1);
        var vars = query.split('&');
        var returnVal = false;
        var pair;
        var i;

        for (i = 0; i < vars.length; i += 1) {
          pair = vars[i].split('=');

          if (pair[0] === variable) {
            returnVal = pair[1];
          }
        }

        return returnVal;
      },

      // Takes a URL and returns an object of the query parameters
      getQueryParamObj: function getQueryParamObj(url) {
        var vars;
        var params = {};
        var i;
        var pair;

        if (url && url !== '') {
          vars = url.substr(url.indexOf('?') + 1).split('&');

          for (i = 0; i < vars.length; i += 1) {
            pair = vars[i].split('=');

            params[pair[0]] = pair[1];
          }

          return params;
        }

        return {};
      },

      // returns window.location.search
      getWindowLocationSearch: function getWindowLocationSearch() {
        return window.location.search;
      },

      // sets location href
      setHref: function setHref(location, href) {
        location.href = href;
      },

      // returns document.readyState
      // helpful for testing
      getDocumentReadyState: function getDocumentReadyState() {
        return document.readyState;
      },

      // jsonParse attempts to parse out a JSON string
      // Catches errors and uses a default value if needed
      jsonParse: function jsonParse(str, defaultValue) {
        try {
          str = JSON.parse(str);
        } catch (err) {
          str = defaultValue || [];
        }

        return str;
      },

      // handles loading a standard app (waiting for document ready and then `new App()`)
      // NOTE: This function proxies to the internal `domLoad` function. Applications
      // needing more specific dom-ready functionality should use the `domLoad` function
      // directly and provide their own callbacks
      loadApp: function loadApp(App) {
        // Wait for DOM load and the create a new App
        this.domLoad(function callback() {
          // eslint-disable-next-line no-new
          new App();
        });
      },

      /**
       * Adds an optional namespace to a specific string.
       *
       * @example
       * Utils.namespaceString( "space", "cowboy", "-" );
       *
       * @param  {string} predicate Generic String
       * @param  {string} namespace Namespace Identifier
       * @param  {string} delimiter Concatenation String
       *
       * @return {string} String to be used in namespace
       */
      namespaceString: function namespaceString(predicate, namespace, delimiter) {
        delimiter = delimiter || ':';

        return predicate + (namespace.length ? delimiter + namespace : '');
      },

      /**
       * parseBreakpoint is used to take a breakpoint string (like "breakpoint-1234")
       * and parse out the number at the end
       *
       * NOTE: not sure `toString()` is required on the `bp` param anymore
       * because of PR #43, but can't hurt.
       *
       * @param {string} bp Breakpoint string
       */
      parseBreakpoint: function parseBreakpoint(bp) {
        var parsed;

        // Check for valid input
        if (bp === undefined) {
          return 0;
        }

        // Breakpoints should be like "breakpoint-1234", so grab just the digits
        parsed = bp.toString().match(/(\d+)$/g);

        return parsed ? ~~parsed[0] : 0;
      },

      // preventEventActions is used to prevent event actions from performing
      // or propagating
      preventEventActions: function preventEventActions(e) {
        // Prevent default if possible
        if (e && e.preventDefault) {
          e.preventDefault();
        }

        // Stop propagation if possible
        if (e && e.stopPropagation) {
          e.stopPropagation();
        }
      },

      // sendChannelMessage is used to send messages on a Backbone.Radio channel
      // NOTE: Will pass through any additional arguments as an array
      sendChannelMessage: function sendChannelMessage(channel) {
        // Trigger event on channel, passing in any arguments available
        channel.trigger.apply(channel, [].slice.call(arguments, 1));
      },

      /**
       * Checks if the targeted page element is contained in the excludes array.
       *
       * This function is used in onBodyClicked, to exclude certain page
       * interactions from firing a given event.
       */
      shouldStop: function shouldStop(e, excludes) {
        var target;
        var i = 0;
        var len;
        var exclude;

        // Make sure excludes was defined as an array
        excludes = $.isArray(excludes) ? excludes : [];

        // Check that target exists and that excludes is not empty
        if (!e.target || !excludes.length) {
          return false;
        }

        // Cache the target of the event
        target = $(e.target);
        len = excludes.length;

        // Iterate over the excludes
        for (; i < len; i += 1) {
          exclude = excludes[i];

          // If the target is one of the excludes, return
          if (target.is(exclude) || target.parents(exclude).length) {
            return true;
          }
        }

        return false;
      },

      // stringify turns an element into a JSON-encoded string,
      // so long as it's not already a string
      stringify: function stringify(el) {
        // Stringify non-strings into JSON strings
        return typeof el === 'string' ? el : JSON.stringify(el);
      },

      // windowLoad is used to call a callback upon either the DOM already being loaded,
      // or upon window load
      windowLoad: function windowLoad(callback) {
        // Check callback
        callback = this.errorCheckCallback(callback);

        return this.getDocumentReadyState() === 'complete'
          ? callback()
          : window.addEventListener('load', callback);
      },

      // returns window.sessionStorage
      getSessionStorage: function getSessionStorage() {
        return window.sessionStorage;
      },

      // HTML5 Session Storage utils with cookies for back up
      sessionStorePut: function sessionStorePut(key, item) {
        // if sessionStorage is present, use that
        var sessionStorage = this.getSessionStorage();

        // stringify items that are objects
        if ($.type(item) !== 'string') {
          item = this.stringify(item);
        }

        if (sessionStorage) {
          // use sessionStorage
          sessionStorage.setItem(key, item);
        } else {
          // without sessionStorage we'll have to use a session scoped cookie
          document.cookie = this.getCookieString(key, item);
        }
      },

      // forms a cookie string
      getCookieString: function getCookieString(key, item) {
        return key + '=' + item + '; expires=0; path=/';
      },

      sessionStoreGet: function sessionStoreGet(key) {
        var returnItem = false;
        var sessionStorage = this.getSessionStorage();
        var name;
        var ca;
        var i;
        var c;

        // if sessionStorage is present, use that
        if (sessionStorage) {
          // use sessionStorage
          returnItem = sessionStorage.getItem(key);
        } else {
          // without sessionStorage we'll have to use a session scoped cookie
          name = key + '=';
          ca = document.cookie.split(';');

          for (i = 0; i < ca.length; i += 1) {
            c = ca[i];

            while (c.charAt(0) === ' ') {
              c = c.substring(1);
            }

            if (c.indexOf(name) === 0) {
              returnItem = c.substring(name.length, c.length);
            }
          }
        }

        return returnItem;
      },

      sessionStoreDelete: function sessionStoreDelete(key) {
        var sessionStorage = this.getSessionStorage();

        // if sessionStorage is present, use that
        if (sessionStorage) {
          // easy object property API
          sessionStorage.removeItem(key);
        } else {
          // deleting the back up cookie
          document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
      },

      /**
       * Executes a function based on a string name and context.
       *
       * @example
       * Utils.executeFunctionByName( "myFunc.init", this, arguments );
       *
       * @param  {string} functionName Name of function to call
       * @param  {Object} context      Context to use.  E.g. window
       * @param  {args} arg1, arg2, arg2 Optional: Arguments to pass to function
       *
       * @return {function}            function to be called
       */
      executeFunctionByName: function executeFunctionByName(functionName, context) {
        var args = [].slice.call(arguments).splice(2);
        var namespaces;
        var func;
        var len;
        var i = 0;

        if (!functionName) {
          return;
        }

        namespaces = functionName.split('.');
        func = namespaces.pop();
        len = namespaces.length;

        context = context || window;

        for (; i < len; i += 1) {
          context = context[namespaces[i]];
        }

        if (typeof context[func] !== 'undefined') {
          context[func].apply(context, args);
        }
      },

      /**
       * Replace macro strings (i.e. `{varname}`) with values from a data
       * object passed in.
       *
       * @example
       * ```js
       * Utils.replaceMacros( "Uploading {current} of {total} files", {
       *     current : 3,
       *     total : 5,
       * } );
       * ```
       *
       * @param  {string} str   String littered with macros to replace.
       * @param  {Object} data  Plain object where macro values can be found.
       *
       * @return {string}       Manipulated string, with macros replaced.
       */
      replaceMacros: function replaceMacros(str, data) {
        var regex;

        // catch invalid parameters
        if (typeof str !== 'string' || !$.isPlainObject(data)) {
          return false;
        }

        // if there's no macros in the string just return the string as is.
        if (str.indexOf('{') === -1) {
          return str;
        }

        regex = new RegExp('{(' + Object.keys(data).join('|') + ')}', 'g');

        // replace each macro with its corresponding data value
        return str
          .replace(regex, function replaceFalsey(match, key) {
            var value = data[key];

            // don't allow plain objects or booleans, they dont
            // convert to strings well.
            if ($.isPlainObject(value) || typeof value === 'boolean') {
              return '';
            }

            // replace with value, or empty string if value is falsey
            return value || '';
          })
          .replace(/\{.*?\}/g, ''); // remove any leftover macros that weren't matched
      },

      /**
       * Get the file extension of a filename that is passed in. A filename
       * that does not have an extension will return an empty string.
       *
       *     getExtension( "somefile.txt" ); // => "txt"
       *
       * @param  {string} filename  The name of the file.
       *
       * @return {string}           The extension of the file.
       */
      getExtension: function getExtension(filename) {
        // Regular expression to grab the file extension
        var re = /(?:\.([^.]+))?$/;

        return re.exec(filename)[1] || '';
      },

      /**
       * Accepts a path and will prepend the current language prefix.
       *
       * @param  {string} path             String to prepend prefix to
       * @param  {string} excludedLanguage String containing language to exclude.
       *
       * @return {string}      String containing language prefix
       */
      prependLanguagePrefix: function prependLanguagePrefix(path, excludedLanguage) {
        var languagePrefix = this.getValue('URL_LANGUAGE_PREFIX', window);

        // Check if path contains leading slash
        if (path.charAt(0) !== '/') {
          path = '/' + path;
        }

        // Check for prefix
        if (!languagePrefix) {
          return path;
        }

        // Check for exclusions
        if (excludedLanguage && excludedLanguage === languagePrefix) {
          return path;
        }

        // Use slice to check if the first letters of the path are equal to the
        // language prefix
        if (path.slice(1, languagePrefix.length + 2) === languagePrefix + '/') {
          return path;
        }

        return '/' + languagePrefix + path;
      },

      /**
       * Localize the date in an element from a provided CSS selector.
       *
       * @param {mixed}   selector    CSS selector, or jQuery object of the element(s)
       *                              containing the date you want to localize.
       *                              NOTE: Element should only contain the date text.
       * @param {string}  [format]    JS date format for moment to use.
       * @param {string}  [tz]        Optionally set timezone explicitly.
       * @param {boolean} [setLocale] Optionally localize any months or weekdays.
       *                              NOTE: This option is handy when displaying, but is
       *                              opt-in to ensure the app against any regressions.
       */
      localizeDateElements: function localizeDateElements(selector, format, tz, setLocale) {
        var $selector = typeof selector === 'string' ? $(selector) : selector;

        // default date format
        format = format || 'MMM DD, YYYY @ h:mm A';

        // Use the existing text and moment.js to update to local time
        $selector.each(function localize(idx, el) {
          var time =
            el.tagName === 'TIME' && el.hasAttribute('datetime')
              ? el.getAttribute('datetime')
              : el.innerText;
          el.innerText = moment.utcToLocal(time, format, tz, setLocale);
        });
      },

      /**
       * Scrolls the page to a specific body DOM element, based on a given number
       *
       * @param {int} nodeNumber integer representing the node we want to scroll to
       * @param {Object} nodeList jQuery objects we're counting as nodes, i.e. $('body').children()
       * @param {jQuery} $root jQuery object representing html page root, i.e. $('html, body')
       * @param {int} [animationSpeed] scroll animation speed in milliseconds (optional)
       */
      scrollToNode: function scrollToNode(nodeNumber, nodeList, $root, animationSpeed) {
        // look up node to scroll to, accounting for zero offset
        var $anchorNode = $(nodeList).eq(nodeNumber - 1);

        // verify valid node number and corresponding DOM element
        if (nodeNumber === 0 || nodeNumber === undefined || !$anchorNode.length) {
          return;
        }

        // scroll to node
        this.scrollPage($anchorNode.offset().top, $root, animationSpeed);
      },

      /**
       * Retrieves the current page hash, without the hash symbol.
       *
       * @return {string} hash value of current URL
       */
      getUrlHash: function getUrlHash() {
        return window.location.hash.replace('#', '');
      },

      /**
       * Creates and starts up a Mutation Observer for a given DOM element
       * Executes a specified callback function based on config parameters
       *
       * Also executes a specified callback function based on config
       * parameters which indicate what counts as a mutation.
       *
       * @param  {Object} el       DOM element that should be observed for mutations.
       * @param  {string} callback Function name to be executed when a mutation occurs.
       * @param  {Object} config   Optional key value pairs of configuration options.
       *
       * @return {Object}          MutationObserver object, so we can disconnect if desired.
       */
      setMutationObserver: function setMutationObserver(el, callback, config) {
        var MutationObserver = this.getMutationObserver();
        // create an observer instance
        var observer = new MutationObserver(function cb(mutations) {
          mutations.forEach(callback);
        });

        // default configuration, if none passed in
        config = config || { attributes: true, childList: true, subtree: true };

        // observe body content for changes
        observer.observe(el, config);

        // return MutationObserver so it can be disconnected
        return observer;
      },

      /**
       * Getter for MutationObserver
       *
       * @return {function} MutationObserver constructor
       */
      getMutationObserver: function getMutationObserver() {
        return MutationObserver;
      },

      /**
       * Translates a message.
       *
       * Should follow the same pattern as the Yii framework to remain
       * consistency with the apps that use this `Utils` library, and
       * will therefore be easier for devs to use.
       *
       *     Utils.t( "app/editor-section", "content-body.help-text" );
       *
       * If your translation has macros:
       *
       *     window.MESSAGES = {
       *         "app/ajax": {
       *             "ajax-message.publish-success": "Your {type} is now live."
       *         }
       *     };
       *
       *     Utils.t( "app/ajax", "ajax-message.publish-success", { type: "content" } );
       *     // => "Your content is now live."
       *
       * @param  {string} category The message category.
       * @param  {string} message  The message to be translated.
       * @param  {Array}  macros   Placeholder macros to replace with data.
       *
       * @return {string}          Translated message.
       */
      t: function t(category, message, macros) {
        var messages = window.MESSAGES;
        var translation;

        // check for translation, if it can't be found, then return missing message
        // NOTE: can't use `getValue` currently because message keys may contain '.'
        if (
          !messages ||
          !messages[category] || // catgory doesn't exist
          !messages[category][message] // message doesn't exist
        ) {
          return (
            '@MISSING TRANSLATION: ' +
            category +
            '.' +
            message +
            ' FOR LANGUAGE ' +
            window.URL_LANGUAGE_PREFIX +
            '@'
          );
        }

        translation = messages[category][message];

        // if macros get passed in, replace them before returning, otherwise
        // just return the translation as-is.
        return macros ? this.replaceMacros(translation, macros) : translation;
      },

      /**
       * Shortens a string based on a character limit and ends it with an ellipsis
       * Only breaks between whole words for cleaner looking text
       *
       * @param {string} text string passed in to be truncated or not
       * @param {int}    maxLength number representing the maximum characters allowed
       *
       * @return {string} truncated text string, or original text string if short enough
       */
      truncateText: function truncateText(text, maxLength) {
        // text must be string and over the char limit
        if (typeof text !== 'string' || text.length < maxLength) {
          return text;
        }

        // shorten and add ellipsis
        return (
          text
            .trim()
            .substring(0, maxLength)
            .split(' ')
            .slice(0, -1)
            .join(' ') + '...'
        );
      },

      /**
       * Animates the page to a given offset, mimicking a user scroll event
       *
       * @param {int} scrollDepth desired offset you'd like to scroll to
       * @param {jQuery} $root jQuery object representing html page root, e.g. $( "html, body" )
       * @param {int} animationSpeed scroll animation speed in milliseconds (optional)
       */
      scrollPage: function scrollPage(scrollDepth, $root, animationSpeed) {
        $root.animate(
          {
            scrollTop: scrollDepth,
          },
          animationSpeed !== undefined ? animationSpeed : 200
        );
      },

      /**
       * Sanitizes multi-line HTML markup from Redactor caption fields.
       *
       * @param {string} markup the WYSIWYG markup to be sanitized
       */
      stripMultiline: function stripMultiline(markup) {
        return markup
          .replace(/<\/?p[^>]*>/gi, '')
          .replace(/<br[^>]*>/gi, '')
          .replace(/\t/gi, '')
          .replace(/\r?\n|\r/gi, ' ')
          .trim();
      },
    };

    return Utils;
  }
);
