define(
  'momentextended',
  ['moment', 'underscore', 'momentimezone'],

  function momentextended(moment, _) {
    _.extend(moment, {
      /**
       * The locale associated with the current site, set on the window object
       * as the attributes of the LocaleModel
       *
       * @type {object}
       */
      currentLocale: window.CURRENT_LOCALE,

      getCurrentUtcTime: function getCurrentUtcTime(format) {
        return this.utc().format(format);
      },

      getTimeZone: function getTimeZone() {
        return this.tz.guess();
      },

      localToUtc: function localToUtc(time, format) {
        // Define our time, but after instantiating a moment object with
        // the given time, put moment in UTC mode.
        var localTime = this(time, format).utc();

        // Validate the local time
        if (!localTime.isValid()) {
          return time;
        }

        // Return the local time, formated
        return localTime.format(format);
      },

      /**
       * Use moment.js to localize the given date in the given format
       * in the given timezone (optional).
       *
       * Optionally translate any strings, ie. week or month, to that
       * of the current locale.
       *
       * @param {mixed}  time          Datetime string or moment object to localize.
       * @param {string} [format]      Date format for moment to use.
       * @param {string} [tz]          Optionally set timezone explicitly.
       * @param {bool}   [setLocale]   Optionally display strings (month or weekday, say)
       *                               in the locale's language.
       * @param {string} [inputFormat] Format of the time parameter
       */
      utcToLocal: function utcToLocal(datetime, format, tz, setLocale, inputFormat) {
        var momentTime;

        // defaults to true
        setLocale = setLocale !== false;

        // if datetime is a string, trim it
        datetime = typeof datetime === 'string' ? datetime.trim() : datetime;

        // The moment time object of this string, using basic datetime format.
        // Conditionally set locale on the moment object, as this may be used for display
        // NOTE: Passing a format into the constructor like so will no longer be needed
        // once we move to inputing strings that are ISO formatted
        // NOTE: make sure to use `utc()` so the moment object is instantiated under
        // the assumption that the string is already a UTC datetime.
        momentTime = setLocale
          ? this.utc(datetime, inputFormat, this.getCurrentLocale())
          : this.utc(datetime, inputFormat);

        // Validate the utc time
        if (!momentTime.isValid()) {
          return datetime;
        }

        // If timezone was passed explicitly, use it, otherwise
        // use local time.
        momentTime = tz ? momentTime.tz(tz) : momentTime.local();

        // Return the local equivilent to the utc time
        return momentTime.format(format);
      },

      /**
       * Return the current locale string associated with the current site
       *
       * @return {string} The string representation, `en` or `it`,
       *                  associated with the current site.
       */
      getCurrentLocale: function getCurrentLocale() {
        if (this.currentLocale === undefined) {
          return 'en';
        }

        // moment uses `<language code>-<country code>` format,  i.e. `en-gb`
        // and `zh-tw`. NOTE, this works even if country code is unnecessary,
        // i.e. `en-us`, `es-es`, `it-it`, etc. so no checks are necessary.
        // Also note, capitalization doesn't matter to moment.
        return this.currentLocale.language + '-' + this.currentLocale.country;
      },
    });

    // Return moment with the extended functions added
    return moment;
  }
);
