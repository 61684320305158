define( [ "backbone", "momentextended" ], function ( Backbone, moment ) {
	"use strict";

	/**
	 * @class DateTimePicker
	 * @extends Backbone.View
	 */
	var DateTimePicker = Backbone.View.extend( {
		el: [], // default to empty object, requiring user to pass in element selector

		events: {
			"change [type='date'], [type='time']": "handleChange",
		},

		dateFormat: "YYYY-MM-DD",

		timeFormat: "HH:mm",

		initialize: function initialize() {
			if ( !this.$el.length ) {
				return;
			}

			// set up change event handler for the view as a whole,
			// to help consumers easily trigger changes as well.
			this.on( "triggerChange", this.handleChange, this );

			this.$postInput = this.$( "input[type='hidden']" );
			this.$dateInput = this.$( "input[type='date']" ).prop( "disabled", false );
			this.$timeInput = this.$( "input[type='time']" ).prop( "disabled", false );

			var currentDate = this.$postInput.val();
			var localDate;

			if ( !currentDate ) {
				return;
			}

			// remove @ symbol from legacy dates to prevent moment.js
			// from possibly failing since it's an invalid format.
			if ( currentDate.indexOf( "@" ) !== -1 ) {
				currentDate = currentDate.replace(" @ ", " ");
				// correct post input value for subsequent saves
				// make sure to pass `utcToLocal` method `false` for the
				// setLocale parameter
				this.$postInput.val( moment.utcToLocal( currentDate, undefined, undefined, false ) );
			}

			// set the date & inputs to show the date in the users current timezone.
			localDate = moment.utcToLocal( currentDate );
			this.setDate( localDate );
			this.setTime( localDate );
		},

		/**
		 * Set the date input based on a date string or object.
		 *
		 * @param {string|Moment|Date} date A date string or object.
		 *
		 * @returns {DateTimePicker}        For chaining.
		 */
		setDate: function setDate( date ) {
			this.$dateInput.val( moment( date ).format( this.dateFormat ) );
			return this;
		},

		/**
		 * Set the time input based on a date string or object.
		 *
		 * @param {string|Moment|Date} date A date string or object.
		 *
		 * @returns {DateTimePicker}        For chaining.
		 */
		setTime: function setTime( date ) {
			if ( !this.$timeInput.length ) {
				return;
			}

			this.$timeInput.val( moment( date ).format( this.timeFormat ) );
			return this;
		},

		/**
		 * Set the date & time based on a single date string/object passed in.
		 *
		 * @param {string|Moment|Date} date A date string or object.
		 *
		 * @returns {DateTimePicker}        For chaining.
		 */
		set: function set( date ) {
			date = moment( date );
			this.setDate( date );
			this.setTime( date );
			this.$postInput.val( date.format() );
			return this;
		},

		/**
		 * Get the current date & time of the picker.
		 *
		 * @returns {Moment} Full current datetime for this picker, in the form
		 *                   of a Moment.js object, to provide flexability.
		 */
		get: function get() {
			var current = this.$postInput ? this.$postInput.val() : null;
			return current ? moment( current ) : null;
		},

		/**
		 * Handle any user updates to the date or time pickers. Reset the
		 * value of the post input to refect the user's changes.
		 *
		 * NOTE: no need to convert anything to UTC, just let moment do it's
		 * thing, and set it to the default ISO 8601 date format, including
		 * the timezone offset. Postgres will do the work to save it in the
		 * correct utc format.
		 */
		handleChange: function handleChange(e) {
			var date = this.$dateInput.val();
			var time = this.$timeInput.val();
			var newDate;
			var today;

			// if no date value is chosen, then nothing should get set,
			// even if a time value exists.
			if ( !date ) {
				this.$postInput.val( "" );
				this.trigger( "change", "" );
				return;
			}

			// if no time field exists, just use the date and return
			if ( !this.$timeInput.length ) {
				this.$postInput.val( date );
				this.trigger( "change", date );
				return;
			}

			today = moment( Date.now() );

			// default to current time if current date was chosen
			if ( e && (e.currentTarget.type === 'date') && today.format( this.dateFormat ) === date ) {
				time = today.format( this.timeFormat );
			}

			// if time field doesn't have a value, default to midnight so
			// moment doesn't create an invalid date
			newDate = moment( date + "T" + ( time || "00:00" ) );
			this.$postInput.val( newDate.format() );

			this.setDate(newDate);
			if (time) {
				this.setTime(newDate);
			}
			this.trigger( "change", newDate );
		},

		/**
		 * Sets picker to the current date & time.
		 *
		 * @returns {DateTimePicker} For chaining.
		 */
		setToNow: function setToNow() {
			// Round to nearest minute for consistency with date picker input
			var now = Date.now();
			this.set( now - now % 60000 );
			return this;
		},

		/**
		 * Set the min value of the date picker.
		 *
		 * @param {string|Moment|Date} date A date string or object.
		 * @returns {DateTimePicker} For chaining.
		 */
		setMinDate: function setMinDate( date ) {
			this.$dateInput.attr( "min", date ? moment( date ).format( this.dateFormat ) : "1801-01-01" );
			return this;
		},

		/**
		 * Set the max value of the date picker.
		 *
		 * @param {string|Moment|Date} date A date string or object.
		 * @returns {DateTimePicker} For chaining.
		 */
		setMaxDate: function setMaxDate( date ) {
			this.$dateInput.attr( "max", date ? moment( date ).format( this.dateFormat ) : "2999-12-31" );
			return this;
		},

		/**
		 * Clear the date and time inputs.
		 *
		 * @returns {DateTimePicker} For chaining.
		 */
		clear: function () {
			this.$dateInput.val( "" );
			this.$timeInput.val( "" );
			this.$postInput.val( "" );

			return this;
		}

	} );

	return DateTimePicker;
});
