/**
 * MediaQueryEvent broadcasts "breakpoint" events when SASS breakpoint thresholds
 * are crossed during resize, and on load. This View uses the "font-family" attribute
 * appended to the html css class to determine breakpoints.
 * See _breakpoint-events.scss in the mp-bower-assets/scss directory
 * For complete options, view here: https://github.com/Hearst-Hatchery/on-media-query
 */

define(
  'mediaqueryevent',

  // Define require.js dependencies
  ['underscore', 'backbone', 'utils', 'logger', 'onmediaquery', 'radio'],

  function mediaqueryevent(_, Backbone, Utils, Logger, MQ) {
    var MediaQueryEvent = Backbone.View.extend({
      initialize: function initialize() {
        this.setVariables();

        this.setBreakpoints();

        this.setListeners();
      },

      setBreakpoints: function setBreakpoints() {
        /**
         * Remove unwanted characters from string for a valid json parse
         * Matches whitespaces, single or double quotes at the beginning and end, slashes in between
         * Most browsers either single or double quote the string.
         * Regex explanation:
         *     ^ # Start at the beginning of the path
         *     ['"] # Matches either single or double strings from beginning and end
         *     ['",] # Android browsers place a "," after an item in the font family list.
         *     \s? # Matches any whitespaces
         *     \\ # Matches any slashes
         * Examples:
         *      "{ "foo": "bar" }" - Double quotes at beginning/end
         *     '{ "foo": "bar" }' - Single quotes at beginning/end
         *      " {   \"foo\": \"bar\" } " - Whitespace and escaping slashes in between
         */
        var cssValue = this.$head.css('font-family');

        cssValue = cssValue
          .substring(cssValue.indexOf('{') - 1)
          .replace(/^['"]+|\s+|\\|(;\s?})+|['",]+$/g, '');

        // Parse breakpoints map into json
        try {
          this.breakpoints = this.sortBreakpoints(JSON.parse(cssValue));
        } catch (err) {
          // This should be fun
          this.logger.error('Breakpoints Parse Error', {
            error: err,
            values: cssValue,
          });
        }
      },

      setVariables: function setVariables() {
        // set up the logger
        this.logger = new Logger('MediaQueryEvent');

        this.mediaQueryChannel = this.createChannel();

        this.$head = $('head');

        this.breakpoints = {};

        this.currentBreakpoint = 0;

        this.currentLabel = 'mobile';

        this.testQueries = [
          {
            context: '*',
            match: this.channelAnnounce.bind(this),
          },
        ];
      },

      setListeners: function setListeners() {
        // Start our MediaQuery Plugin
        // Wrap in setTimeout so the call stack clears before triggering the breakpoint event
        setTimeout(MQ.init.bind(MQ, this.testQueries), 0);
      },

      createChannel: function createChannel() {
        // Please note, this needs to happen at the top of the Backbone Stack
        // Otherwise remote listeners may miss the initial triggered event for document.ready
        // In extreme cases, a listener can point to the "breakpoint" property
        // But that requires a proper context being set to "GlobalScript"
        return Backbone.Radio.channel('mediaquery');
      },

      sortBreakpoints: function sortBreakpoints(breakpoints) {
        var values = _.invert(breakpoints);
        var sortedBreakpoints = {};

        _.toArray(breakpoints)
          .sort(function sort(a, b) {
            return a > b;
          })
          .forEach(function setSortedBreakpoints(value) {
            sortedBreakpoints[values[value]] = value;
          });

        return sortedBreakpoints;
      },

      getCurrentLabel: function getCurrentLabel() {
        var currentLabel = this.currentLabel;

        // Loop through values and find the key in range
        Object.keys(this.breakpoints).forEach(
          function setCurrentLabel(key) {
            if (~~this.breakpoints[key] > this.currentBreakpoint) {
              return false;
            }

            currentLabel = key;

            return currentLabel;
          }.bind(this)
        );

        return currentLabel;
      },

      is: function is(label) {
        return label in this.breakpoints && this.currentLabel === label;
      },

      isGt: function isGt(label) {
        return label in this.breakpoints && this.currentBreakpoint > this.breakpoints[label];
      },

      isGte: function isGte(label) {
        return label in this.breakpoints && this.currentBreakpoint >= this.breakpoints[label];
      },

      isLt: function isLt(label) {
        return label in this.breakpoints && this.currentBreakpoint < this.breakpoints[label];
      },

      isLte: function isLte(label) {
        return label in this.breakpoints && this.currentBreakpoint <= this.breakpoints[label];
      },

      channelAnnounce: function channelAnnounce(breakpoint) {
        this.currentBreakpoint = Utils.parseBreakpoint(breakpoint);

        this.currentLabel = this.getCurrentLabel();

        this.mediaQueryChannel.trigger('breakpoint', breakpoint);
      },
    });

    return MediaQueryEvent;
  }
);
